(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-admin-drawer.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-admin-drawer.js');
'use strict';

const lbUiMockable = svs.components.lbUi;
const chatUiMockable = svs.components.chat.chatUi;
const {
  Head
} = svs.components.chat.chatUi;
const {
  ReactIcon,
  Button
} = svs.ui;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_LG
} = svs.components.lbUtils.useMediaQuery;
const {
  useState
} = React;
const ChatAdminDrawer = _ref => {
  let {
    customerId,
    visible,
    toggleChat,
    canEditUser,
    roomId,
    headline,
    isEnabled,
    toggleStatus,
    allowedToModerateChat,
    allowedToUnpublishChat,
    onShowDialog
  } = _ref;
  const [activeTab, setActiveTab] = useState('chat');
  const isWideDevice = useMediaQuery(breakpoints.up(BREAKPOINT_LG));
  const getStyle = () => {
    const drawerStyle = {};
    if (isWideDevice && document.querySelector('.header')) {
      drawerStyle.marginTop = '';

      const header = document.querySelector('.header');
      const {
        bottom
      } = header.getBoundingClientRect();
      drawerStyle.marginTop = bottom;
    }
    return drawerStyle;
  };
  const drawerStyle = getStyle();
  drawerStyle.maxWidth = 616;
  if (isWideDevice) {
    drawerStyle.zIndex = 1900;
  }
  const title = 'Vill du verkligen inaktivera kommentarsfältet?';
  const text = 'Kunderna kommer inte att kunna se och skriva kommentarer förrän kommentarsfältet aktiveras igen.';
  const buttons = [{
    text: 'Avbryt',
    classes: {
      inverted: true
    }
  }, {
    text: 'Inaktivera',
    classes: {
      focus: true
    },
    callback: () => {
      toggleStatus(roomId);
    }
  }];
  return React.createElement(lbUiMockable.Drawer, {
    className: "drawer-chat",
    direction: "right",
    isOpen: visible,
    isOverlayDisabled: true,
    onClickOverlay: toggleChat,
    style: drawerStyle
  }, React.createElement(chatUiMockable.ChatAdminContainer, {
    activeTab: activeTab,
    canEditUser: canEditUser,
    customerId: customerId,
    isEnabled: isEnabled,
    roomId: roomId
  }, React.createElement(Head, {
    headline: headline
  }, React.createElement("button", {
    className: "chat-head--btn qa-chat-close-btn icon-button",
    onClick: () => toggleChat(roomId),
    type: "button"
  }, React.createElement(ReactIcon, {
    color: "fc-grey-600",
    icon: "menu-right",
    size: "100"
  })), React.createElement("div", {
    className: "retail-chat-selector btn-group"
  }, React.createElement("button", {
    className: ['btn btn-100', activeTab === 'chat' ? 'btn-inverted' : 'btn-transparent-default', 'no-wrap margin-0 qa-retail-chat-comments padding'].join(' '),
    onClick: () => setActiveTab('chat'),
    type: "button"
  }, "Kommentarer"), React.createElement("button", {
    className: ['btn btn-100', activeTab === 'blocked' ? 'btn-inverted' : 'btn-transparent-default', 'no-wrap margin-0 qa-retail-chat-block-user padding'].join(' '),
    disabled: !allowedToModerateChat,
    onClick: () => setActiveTab('blocked'),
    type: "button"
  }, "Blockerade kunder")), React.createElement(Button, {
    className: "btn-icon btn-icon-right qa-retail-chat-disable",
    disabled: !allowedToUnpublishChat,
    onClick: () => isEnabled ? onShowDialog({
      title,
      text,
      buttons
    }) : toggleStatus(roomId),
    size: 100,
    transparent: true
  }, isEnabled ? 'Inaktivera' : 'Aktivera', React.createElement(ReactIcon, {
    icon: isEnabled ? 'eye-closed' : 'eye',
    size: "100"
  })))));
};
ChatAdminDrawer.defaultProps = {
  allowedToModerateChat: true,
  visible: false
};
setGlobal('svs.components.chat.chatUi.ChatAdminDrawer', ChatAdminDrawer);

 })(window);