(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-blocked-user.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-blocked-user.js');
'use strict';

const {
  ReactIcon
} = svs.ui;
function BlockedUser() {
  return React.createElement("div", {
    className: "chat-container--blocked"
  }, React.createElement(ReactIcon, {
    color: "fc-grey-600",
    icon: "info",
    size: "400"
  }), "Butiken har sp\xE4rrat dig fr\xE5n att skriva kommentarer.");
}
setGlobal('svs.components.chat.chatUi.BlockedUser', BlockedUser);

 })(window);