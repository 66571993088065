(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-button.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-button.js');

'use strict';

const _excluded = ["count", "roomId"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const floatingActionButtonMockable = svs.components.lbUi.floatingActionButton;
const uiMockable = svs.ui;
const {
  ChatUnreadCount
} = svs.components.chat.chatUi;
const renderIcon = hasUnreadMessages => hasUnreadMessages ? React.createElement(uiMockable.ReactIcon, {
  color: "fc-red",
  icon: "chat-filled",
  size: "200"
}) : React.createElement(uiMockable.ReactIcon, {
  color: "fc-grey-600",
  icon: "chat-host",
  size: "200"
});
const ChatButton = _ref => {
  let {
      count,
      roomId
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(floatingActionButtonMockable.FloatingActionButtonContainer, _extends({
    className: "chat-btn",
    willReceiveStartPos: true
  }, props), React.createElement("div", {
    className: "relative"
  }, renderIcon(Boolean(count)), React.createElement(ChatUnreadCount, {
    className: "chat-btn-unread-count",
    "data-testid": "chat-btn-unread"
  }, count)));
};
setGlobal('svs.components.chat.chatUi.ChatButton', ChatButton);

 })(window);