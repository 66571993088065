(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-admin-component.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-admin-component.js');


'use strict';

const chatUiMockable = svs.components.chat.chatUi;
const socketMockable = svs.components.lbUtils.socket;
const {
  useState,
  useEffect,
  useRef,
  useCallback
} = React;
const {
  logger,
  ChatAdminBlockedContainer
} = svs.components.chat.chatUi;
const {
  helpers,
  constants
} = svs.components.chat.chatData;
const {
  MESSAGE_STATUS_SEND_PENDING,
  MESSAGE_STATUS_SEND_FAILED,
  MESSAGE_STATUS_SENT,
  MESSAGE_STATUS_DELETE_PENDING,
  MESSAGE_STATUS_DELETE_FAILED,
  MESSAGE_STATUS_DELETED,
  MESSAGE_STATUS_NORMAL,
  MESSAGE_STATUS_EDITED,
  MESSAGE_STATUS_INFO,
  MAX_CHARACTER_COUNT
} = constants;
const ChatAdminComponent = _ref => {
  let {
    canEditUser,
    onInitializeComponent,
    visible,
    onMarkRead,
    messageList,
    roomId,
    onFetchMessages,
    onGetUnread,
    onSocketUpdate,
    newMessage,
    onSubmitMessage,
    children,
    error,
    onUnblockUser,
    hasLoadedEverything,
    loading,
    onDeleteMessage,
    onBlockUser,
    onDeleteQueuedMessage,
    onResendMessage,
    isEnabled,
    activeTab,
    onGetBlockedUsers,
    onShowDialog
  } = _ref;
  const [isSubscribed, setSubscribed] = useState(false);
  useEffect(() => {
    onInitializeComponent();
  }, []);
  useEffect(() => {
    if (visible) {
      const newServerMsgs = messageList.filter(m => !m.localId);
      const newLastId = (newServerMsgs.pop() || {}).id;
      if (newLastId) {
        onMarkRead(newLastId);
      }
    }
  }, [visible, onMarkRead]);
  const hasLogged = useRef(false);
  useEffect(() => {
    if (!hasLogged.current) {
      messageList.length > 100 && logger.warn("Client has many messages, roomId: ".concat(roomId, ", ").concat(messageList.length, " "));
      hasLogged.current = true;
    }
  }, [roomId]);
  const messageListRef = useRef();
  function handleOnChange() {
    messageListRef.current.scrollToBottom();
  }
  function handleSocketUpdate() {
    onSocketUpdate(...arguments);
    if (!isSubscribed) {
      onFetchMessages({
        reset: true
      });
      onGetUnread();
      setSubscribed(true);
    }
  }
  const validationError = helpers.validateChatMessage(newMessage, MAX_CHARACTER_COUNT);
  const handleFetchBlockedUsers = useCallback(() => {
    onGetBlockedUsers(roomId);
  }, [onGetBlockedUsers, roomId]);
  return React.createElement(socketMockable.Socket, {
    onUpdate: handleSocketUpdate,
    path: "/chat/1/rooms/retailer/".concat(roomId, "/messages/changes"),
    sendInitialUpdate: true
  }, _ref2 => {
    let {
      error: socketError,
      retryReconnect
    } = _ref2;
    return React.createElement("div", {
      className: "chat-container"
    }, children, React.createElement("div", {
      className: "chat-content"
    }, activeTab === 'chat' && React.createElement(React.Fragment, null, !isEnabled && React.createElement("div", {
      className: "fc-red align-center padding-2"
    }, "Kommentarsf\xE4ltet \xE4r inaktiverat och visas inte f\xF6r kunderna"), React.createElement(chatUiMockable.MessageList, {
      canEditUser: canEditUser,
      error: error || (socketError ? new Error('Kunde inte ansluta till chatten för tillfället') : null),
      hasLoadedEverything: hasLoadedEverything,
      isEnabled: isEnabled,
      loading: loading || !isSubscribed && !socketError,
      messageList: messageList,
      onBlockUser: onBlockUser,
      onDeleteMessage: onDeleteMessage,
      onDeleteQueuedMessage: onDeleteQueuedMessage,
      onFetchMessages: onFetchMessages,
      onResendMessage: onResendMessage,
      onRetryReconnect: error ? onFetchMessages : retryReconnect,
      ref: messageListRef
    })) || null, activeTab === 'chat' && isEnabled && React.createElement(chatUiMockable.WriteMessage, {
      disabled: Boolean(validationError),
      newMessage: newMessage,
      onSubmitMessage: onSubmitMessage,
      onUpdateNewMessage: handleOnChange
    }) || null, activeTab === 'blocked' && React.createElement(ChatAdminBlockedContainer, {
      onFetchBlockedUsers: handleFetchBlockedUsers,
      onShowDialog: onShowDialog,
      onUnblockUser: onUnblockUser,
      roomId: roomId
    }) || null));
  });
};
ChatAdminComponent.defaultProps = {
  isEnabled: true,
  messageList: [],
  newMessage: '',
  onInitializeComponent: () => {}
};
setGlobal('svs.components.chat.chatUi.ChatAdminComponent', ChatAdminComponent);

 })(window);