(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/head.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/head.js');

'use strict';

const Head = _ref => {
  let {
    children,
    headline
  } = _ref;
  return React.createElement("div", {
    className: "chat-head"
  }, children, React.createElement("span", {
    className: "chat-head--title"
  }, headline));
};
setGlobal('svs.components.chat.chatUi.Head', Head);

 })(window);