(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/write-message.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/write-message.js');
'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const contentEditableMockable = svs.components.lbUi.ContentEditable;
const {
  Button,
  ReactIcon
} = svs.ui;
const {
  constants
} = svs.components.chat.chatData;
const debounceRate = 300;
const defaultProps = {
  isCurrentUserAuthenticated: true
};
class WriteMessage extends React.Component {
  constructor() {
    var _this;
    super(...arguments);
    _this = this;
    _defineProperty(this, "state", {
      value: ''
    });
    _defineProperty(this, "handleInput", function () {
      let newValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      const processedVal = newValue.replace(/^\s*/, ''); 

      if (_this.debounceTimer) {
        clearTimeout(_this.debounceTimer);
      }
      _this.debounceTimer = setTimeout(() => {
        _this.props.onUpdateNewMessage(processedVal);
      }, debounceRate);
      _this.setState({
        value: processedVal
      });
    });
    _defineProperty(this, "handleSubmit", () => {
      const {
        disabled,
        onSubmitMessage,
        onUpdateNewMessage
      } = this.props;
      if (this.state.value === '') {
        return;
      }
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      onUpdateNewMessage(this.state.value);
      !disabled && onSubmitMessage(this.state.value);
      this.setState({
        value: ''
      });
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.disabled !== this.props.disabled || nextState.value !== this.state.value;
  }
  render() {
    const {
      disabled,
      isCurrentUserAuthenticated
    } = this.props;
    const {
      value
    } = this.state;
    const classNames = [];
    classNames.push('chat-add-comment--placeholder');
    if (value.length > 0) {
      classNames.push('chat-add-comment-placeholder-hidden');
    }
    return React.createElement("div", {
      className: "chat-container--add-comment",
      "data-testid": "chat-write-message"
    }, Boolean(isCurrentUserAuthenticated) && React.createElement(React.Fragment, null, React.createElement("div", {
      className: "relative"
    }, React.createElement("div", {
      className: "chat-add-comment"
    }, React.createElement(contentEditableMockable.ContentEditable, {
      className: "chat-add-comment--text qa-chat-textfield",
      "data-testid": "chat-textfield",
      maxLength: constants.MAX_CHARACTER_COUNT,
      onChange: this.handleInput,
      onEnter: this.handleSubmit,
      value: value
    }), React.createElement(Button, {
      className: "chat-add-comment--create-btn icon-button qa-send-chat-message icon-button",
      "data-testid": "send-chat-message",
      disabled: disabled || value.length === 0,
      onClick: this.handleSubmit
    }, React.createElement(ReactIcon, {
      icon: "send",
      size: "400"
    }))), React.createElement("div", {
      className: "chat-add-comment-placeholder-container"
    }, React.createElement("div", {
      className: classNames.join(' '),
      ref: this.placeholderRef
    }, "Skriv ett meddelande..."))), React.createElement("span", {
      className: "chat-add-comment--counter fc-grey-400 f-center f-100 qa-char-count"
    }, "".concat(constants.MAX_CHARACTER_COUNT - value.length, " tecken kvar"))), !isCurrentUserAuthenticated && 'Du måste logga in för att kunna skriva i chatten.');
  }
}
WriteMessage.defaultProps = defaultProps;
setGlobal('svs.components.chat.chatUi.WriteMessage', WriteMessage);

 })(window);