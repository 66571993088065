(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-admin-blocked-container.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-admin-blocked-container.js');

'use strict';

const {
  ChatAdminBlocked
} = svs.components.chat.chatUi;
const {
  chatData
} = svs.components.chat;
const mapStateToProps = (appState, ownProps) => ({
  blockedUsers: chatData.helpers.getBlockedUsersFromRoom(ownProps.roomId, appState)
});
const ChatAdminBlockedContainer = ReactRedux.connect(mapStateToProps)(ChatAdminBlocked);
setGlobal('svs.components.chat.chatUi.ChatAdminBlockedContainer', ChatAdminBlockedContainer);

 })(window);