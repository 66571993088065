(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-admin-blocked.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-admin-blocked.js');
'use strict';

const {
  useEffect
} = React;
const {
  ReactIcon
} = svs.ui;
const ChatAdminBlocked = _ref => {
  let {
    blockedUsers,
    onFetchBlockedUsers,
    onShowDialog,
    onUnblockUser
  } = _ref;
  useEffect(() => {
    onFetchBlockedUsers();
  }, [onFetchBlockedUsers]);
  const handleBlockUser = user => {
    const title = "Vill du till\xE5ta ".concat(user.customerName || 'anonym', " att kommentera igen?");
    const text = '';
    const buttons = [{
      text: 'Avbryt',
      classes: {
        inverted: true
      }
    }, {
      text: 'Aktivera',
      classes: {
        focus: true
      },
      callback: () => {
        onUnblockUser(user.userId);
      }
    }];
    onShowDialog({
      title,
      text,
      buttons,
      icon: 'chat-host'
    });
  };
  return React.createElement("div", null, blockedUsers.length > 0 ? React.createElement("ul", {
    className: "blocked-users"
  }, blockedUsers.map(user => React.createElement("li", {
    className: "blocked-user-item",
    key: user.userId
  }, React.createElement("div", {
    className: "blocked-user-name f-medium"
  }, user.customerName), React.createElement("div", {
    className: "blocker-name"
  }, user.blockedByName), React.createElement("div", {
    className: "padding-left"
  }, React.createElement("button", {
    className: "qa-unblock icon-button",
    onClick: () => handleBlockUser(user),
    type: "button"
  }, React.createElement(ReactIcon, {
    color: "fc-grey-600",
    icon: "rectify-error",
    size: "300"
  })))))) : React.createElement("div", {
    className: "f-center padding"
  }, React.createElement(ReactIcon, {
    icon: "info",
    size: "100"
  }), React.createElement("span", {
    className: "f-200 margin-left-half"
  }, "Ingen har blivit blockerad fr\xE5n chatten")));
};
ChatAdminBlocked.defaultProps = {
  blockedUsers: [],
  onFetchBlockedUsers: () => {}
};
setGlobal('svs.components.chat.chatUi.ChatAdminBlocked', ChatAdminBlocked);

 })(window);