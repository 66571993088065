(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-mobile.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-mobile.js');
'use strict';

const chatUiMockable = svs.components.chat.chatUi;
const {
  Head
} = svs.components.chat.chatUi;
const {
  ReactIcon
} = svs.ui;
const {
  breakpoints,
  useMediaQuery,
  BREAKPOINT_LG
} = svs.components.lbUtils.useMediaQuery;
const {
  Drawer
} = svs.components.lbUi;
const ChatMobile = _ref => {
  let {
    customerId,
    visible,
    toggleChat,
    roomId,
    headline,
    isRetail
  } = _ref;
  const isWideDevice = useMediaQuery(breakpoints.up(BREAKPOINT_LG));
  const getStyle = () => {
    const drawerStyle = {};
    if (isWideDevice && document.querySelector('.header')) {
      drawerStyle.marginTop = '';

      const header = document.querySelector('.header');
      const {
        bottom
      } = header.getBoundingClientRect();
      drawerStyle.marginTop = bottom;
    }
    return drawerStyle;
  };
  const drawerStyle = getStyle();
  if (isWideDevice) {
    drawerStyle.zIndex = 1900;
  }
  return (
    React.createElement(Drawer, {
      className: "drawer-chat",
      direction: "right",
      isOpen: visible,
      onClickOverlay: toggleChat,
      style: drawerStyle
    }, React.createElement(chatUiMockable.ChatComponent, {
      customerId: customerId,
      isRetail: isRetail,
      roomId: roomId
    }, React.createElement(Head, {
      headline: headline
    }, React.createElement("button", {
      className: "chat-head--btn qa-chat-close-btn icon-button",
      "data-testid": "chat-close-btn",
      onClick: () => toggleChat(roomId),
      type: "button"
    }, React.createElement(ReactIcon, {
      color: "fc-grey-600",
      icon: "menu-right",
      size: "100"
    })))))
  );
};
ChatMobile.defaultProps = {
  visible: false
};
setGlobal('svs.components.chat.chatUi.ChatMobile', ChatMobile);

 })(window);