(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/prescript.js');

'use strict';

svs.components = svs.components || {};
svs.components.marketplace = svs.components.marketplace || {};
svs.components.chat.chatUi = {};
svs.components.chat.chatUi.logger = svs.core.log.getLogger('chat.chatUi');


 })(window);