(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-admin-container.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-admin-container.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  ChatAdminComponent
} = svs.components.chat.chatUi;
const {
  chatData
} = svs.components.chat;
const {
  actions: dialogActions
} = svs.components.marketplaceData.dialogWrapper;
const mapDispatchToProps = (dispatch, ownProps) => ({
  onGetUnread: () => dispatch(chatData.actions.getUnreadByRoom(ownProps.roomId)),
  onFetchMessages: options => dispatch(chatData.actions.fetchMessagesByRoomId(ownProps.roomId, options)),
  onMarkRead: messageId => dispatch(chatData.actions.markRead(messageId, ownProps.roomId)),
  onResendMessage: localId => dispatch(chatData.actions.retrySendMessage(ownProps.roomId, localId)),
  onSubmitMessage: message => dispatch(chatData.actions.sendMessageToRoom(message, ownProps.roomId)),
  onSocketUpdate: response => dispatch(chatData.actions.socketReceiveMessage(ownProps.roomId, response)),
  onDeleteMessage: messageId => dispatch(chatData.actions.deleteMessage(messageId, ownProps.roomId)),
  onDeleteQueuedMessage: localId => dispatch(chatData.actions.deleteQueuedMessage(localId, ownProps.roomId)),
  onGetBlockedUsers: roomId => dispatch(chatData.actions.getBlockedUsers(roomId)),
  onBlockUser: customerId => dispatch(chatData.actions.blockUser(ownProps.roomId, customerId)),
  onUnblockUser: customerId => dispatch(chatData.actions.unblockUser(ownProps.roomId, customerId)),
  onShowDialog: options => dispatch(dialogActions.showDialog(options)),
  onInitializeComponent: () => dispatch({
    type: chatData.actions.CHAT_INITIALIZE_COMPONENT_STATE,
    customerId: ownProps.customerId
  })
});
const mapStateToProps = (state, ownProps) => {
  const room = state.Chat.rooms[ownProps.roomId] || {
    newMessage: '',
    loading: false,
    error: null,
    everything: false
  };
  return _objectSpread(_objectSpread({}, room), {}, {
    messageList: chatData.helpers.getMessageListForRoom(ownProps.roomId, state.Chat),
    visible: ownProps.isAlwaysVisible || state.Chat.isOpen
  });
};
const ChatAdminContainer = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ChatAdminComponent);
ChatAdminContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  canEditUser: PropTypes.bool.isRequired,
  customerId: PropTypes.number.isRequired,
  isAlwaysVisible: PropTypes.bool,
  isEnabled: PropTypes.bool.isRequired,
  roomId: PropTypes.string.isRequired
};
ChatAdminContainer.defaultProps = {
  isAlwaysVisible: false
};
setGlobal('svs.components.chat.chatUi.ChatAdminContainer', ChatAdminContainer);

 })(window);