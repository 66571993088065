(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-more-messages-btn.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-more-messages-btn.js');

'use strict';

const _excluded = ["className", "show"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const {
  ReactIcon,
  Button
} = svs.ui;
const MoreMessagesButton = _ref => {
  let {
      className,
      show
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  return React.createElement("div", {
    className: "chat--more-messages"
  }, React.createElement(Button, _extends({
    className: "chat--more-messages-btn qa-chat-more-messages".concat(show ? ' chat--more-messages-btn-is-visible' : '').concat(className ? " ".concat(className) : ''),
    inverted: true,
    size: 100
  }, props), React.createElement(ReactIcon, {
    color: "fc-white",
    icon: "arrow-down",
    size: "100"
  }), "Nya meddelanden"));
};
MoreMessagesButton.defaultProps = {
  show: true
};
setGlobal('svs.components.chat.chatUi.MoreMessagesButton', MoreMessagesButton);

 })(window);