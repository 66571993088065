(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-desktop-container.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-desktop-container.js');
'use strict';

const chatUiMockable = svs.components.chat.chatUi;
const {
  ChatComponent
} = svs.components.chat.chatUi;
const ChatDesktopContainer = _ref => {
  let {
    customerId,
    roomId,
    headline,
    isRetail
  } = _ref;
  return React.createElement(ChatComponent, {
    customerId: customerId,
    isAlwaysVisible: true,
    isRetail: isRetail,
    roomId: roomId
  }, React.createElement(chatUiMockable.Head, {
    headline: headline
  }));
};
setGlobal('svs.components.chat.chatUi.ChatDesktopContainer', ChatDesktopContainer);

 })(window);