(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-admin-drawer-container.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-admin-drawer-container.js');

'use strict';

const {
  ChatAdminDrawer
} = svs.components.chat.chatUi;
const {
  chatData
} = svs.components.chat;
const {
  roles
} = svs.components.marketplaceRetailAdminData.auth;
const {
  actions: dialogActions
} = svs.components.marketplaceData.dialogWrapper;
const {
  ROOM_STATUS_OPEN
} = svs.components.chat.chatData.constants;
const mapStateToProps = (state, ownProps) => ({
  visible: state.Chat.isOpen,
  isEnabled: (state.Chat.rooms[ownProps.roomId] || {}).status === ROOM_STATUS_OPEN,
  allowedToModerateChat: roles.hasPermission(state.EditorSession.role, roles.PERMISSIONS.MODERATE_CHAT),
  allowedToUnpublishChat: roles.hasPermission(state.EditorSession.role, roles.PERMISSIONS.UNPUBLISH_CHAT)
});
const mapDispatchToProps = dispatch => ({
  toggleChat: roomId => dispatch(chatData.actions.toggleChat(roomId)),
  toggleStatus: roomId => dispatch(chatData.actions.toggleStatus(roomId)),
  onShowDialog: options => dispatch(dialogActions.showDialog(options))
});
svs.components.chat.chatUi.ChatAdminDrawerContainer = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ChatAdminDrawer);

 })(window);