(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-button-container.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-button-container.js');

'use strict';

const {
  ChatButton
} = svs.components.chat.chatUi;
const {
  chatData
} = svs.components.chat;
const mapStateToProps = (state, ownProps) => ({
  count: ownProps.isUnreadCountVisible ? (state.Chat.rooms[ownProps.roomId] || {}).unread || 0 : 0
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => dispatch(chatData.actions.toggleChat(ownProps.roomId))
});
const ChatButtonContainer = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ChatButton);
ChatButtonContainer.propTypes = {
  isUnreadCountVisible: PropTypes.bool,
  roomId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
ChatButtonContainer.defaultProps = {
  isUnreadCountVisible: true
};
setGlobal('svs.components.chat.chatUi.ChatButtonContainer', ChatButtonContainer);

 })(window);