(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-page-loader.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-page-loader.js');

'use strict';

const reactLoaderMockable = svs.components.ReactLoader;
const {
  Button
} = svs.ui;
const ChatPageLoader = React.forwardRef((_ref, ref) => {
  let {
    loading,
    hasLoadedEverything,
    error,
    onFetchMessages,
    onRetryReconnect,
    empty
  } = _ref;
  if (hasLoadedEverything && empty) {
    return React.createElement("div", {
      className: "f-center",
      ref: ref
    }, React.createElement("i", {
      className: "icon-default icon-chat-host icon-800 margin-bottom-1 qa-empty-room"
    }), React.createElement("div", null, "Inga kommentarer"));
  }
  return React.createElement("div", {
    className: "marketplace-chat-page-loader",
    ref: ref
  }, React.createElement("span", {
    className: "fc-red f-center"
  }, error && (error.message || 'Någonting gick snett!')), React.createElement("div", {
    className: "chat-loader-lines"
  }, Boolean(hasLoadedEverything) && React.createElement("span", {
    className: "qa-chat-beginning f-100 fc-grey-500"
  }, "B\xF6rjan av konversationen"), Boolean(!hasLoadedEverything && loading) && React.createElement(reactLoaderMockable.Loader, {
    inverted: true,
    size: 200
  }), Boolean(!hasLoadedEverything && !loading) && React.createElement(Button, {
    className: "qa-load-page",
    onClick: error ? onRetryReconnect : onFetchMessages,
    size: 100
  }, error ? 'Försök igen' : 'Ladda fler')));
});
ChatPageLoader.defaultProps = {
  empty: false,
  error: null,
  hasLoadedEverything: false,
  loading: false
};
setGlobal('svs.components.chat.chatUi.ChatPageLoader', ChatPageLoader);

 })(window);