(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-component.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-component.js');


'use strict';

const {
  useCallback,
  useState,
  useEffect,
  useRef
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const socketMockable = svs.components.lbUtils.socket;
const chatUiMockable = svs.components.chat.chatUi;
const Button = svs.ui.ReactButton;
const {
  logger
} = svs.components.chat.chatUi;
const {
  actions,
  helpers,
  constants
} = svs.components.chat.chatData;
const {
  ROOM_STATUS_OPEN,
  MAX_CHARACTER_COUNT
} = constants;
const ChatComponent = _ref => {
  let {
    isAlwaysVisible,
    customerId,
    roomId,
    children,
    isRetail
  } = _ref;
  const dispatch = useDispatch();
  const [isSubscribed, setSubscribed] = useState(false);
  const hasLogged = useRef(false);
  const messageListRef = useRef();

  const room = useSelector(state => state.Chat.rooms[roomId] || {
    newMessage: '',
    loading: false,
    error: null,
    everything: false
  });
  const messageList = useSelector(state => helpers.getMessageListForRoom(roomId, state.Chat));
  const isVisible = useSelector(state => isAlwaysVisible || state.Chat.isOpen);
  const isEnabled = useSelector(state => (state.Chat.rooms[roomId] || {}).status === ROOM_STATUS_OPEN);
  const isCurrentUserAuthenticated = useSelector(state => state.customerId !== null);

  const onResendMessage = localId => dispatch(actions.retrySendMessage(roomId, localId));
  const onSubmitMessage = message => dispatch(actions.sendMessageToRoom(message, roomId));
  const onDeleteMessage = messageId => dispatch(actions.deleteMessage(messageId, roomId));
  const onDeleteQueuedMessage = localId => dispatch(actions.deleteQueuedMessage(localId, roomId));
  const onFetchMessages = useCallback(options => dispatch(actions.fetchMessagesByRoomId(roomId, options)), [dispatch, roomId]);
  const onSocketUpdate = useCallback(response => dispatch(actions.socketReceiveMessage(roomId, response)), [dispatch, roomId]);
  const onInitializeComponent = useCallback(() => dispatch({
    type: actions.CHAT_INITIALIZE_COMPONENT_STATE,
    customerId
  }), [customerId, dispatch]);
  const newServerMsgs = messageList.filter(m => !m.localId);
  const newLastId = (newServerMsgs.pop() || {}).id;
  const {
    error,
    hasLoadedEverything,
    isUserBlocked,
    loading,
    newMessage
  } = room;
  useEffect(() => {
    onInitializeComponent();
  }, [onInitializeComponent]);
  useEffect(() => {
    if (isVisible && newLastId) {
      dispatch(actions.markRead(newLastId, roomId));
    }
  }, [dispatch, isVisible, newLastId, roomId]);
  useEffect(() => {
    if (!hasLogged.current) {
      messageList.length > 100 && logger.warn("Client has many messages, roomId: ".concat(roomId, ", ").concat(messageList.length, " "));
      hasLogged.current = true;
    }
  }, [messageList, roomId]);
  function handleOnChange() {
    messageListRef.current.scrollToBottom();
  }
  const handleSignIn = () => {
    svs.components.customer_login.login();
  };
  const handleSocketUpdate = useCallback(function () {
    onSocketUpdate(...arguments);
    if (!isSubscribed) {
      if (isCurrentUserAuthenticated) {
        onFetchMessages({
          reset: true,
          fetchRoom: isCurrentUserAuthenticated
        });
        dispatch(actions.getUnreadByRoom(roomId));
      }
      setSubscribed(true);
    }
  }, [dispatch, isCurrentUserAuthenticated, isSubscribed, onFetchMessages, onSocketUpdate, roomId]);
  const validationError = helpers.validateChatMessage(newMessage, MAX_CHARACTER_COUNT);
  return React.createElement(socketMockable.Socket, {
    onUpdate: handleSocketUpdate,
    path: "/chat/1/rooms/".concat(isRetail ? 'retailer/' : '').concat(roomId, "/messages/changes"),
    sendInitialUpdate: true
  }, _ref2 => {
    let {
      error: socketError,
      retryReconnect
    } = _ref2;
    if (!isCurrentUserAuthenticated) {
      return React.createElement("div", {
        className: "chat-container qa-chat-container"
      }, children, React.createElement("div", {
        className: "padding-1 f-100 align-center fc-grey-400"
      }, React.createElement("p", null, "Du m\xE5ste logga in f\xF6r att kunna se chatten."), React.createElement(Button, {
        className: "external-prompt-btn",
        inverted: true,
        onClick: handleSignIn,
        size: 300
      }, "LOGGA IN")));
    }
    return React.createElement("div", {
      className: "chat-container qa-chat-container"
    }, children, Boolean(isEnabled) && React.createElement(React.Fragment, null, React.createElement("div", {
      className: "chat-content"
    }, React.createElement(chatUiMockable.MessageList, {
      error: error || socketError && new Error('Kunde inte ansluta till chatten för tillfället'),
      hasLoadedEverything: hasLoadedEverything,
      isCurrentUserAuthenticated: isCurrentUserAuthenticated,
      loading: loading || !isSubscribed && !socketError,
      messageList: messageList,
      onDeleteMessage: onDeleteMessage,
      onDeleteQueuedMessage: onDeleteQueuedMessage,
      onFetchMessages: onFetchMessages,
      onResendMessage: onResendMessage,
      onRetryReconnect: error ? onFetchMessages : retryReconnect,
      ref: messageListRef
    })), Boolean(isUserBlocked) && React.createElement(chatUiMockable.BlockedUser, null), !isUserBlocked && React.createElement(chatUiMockable.WriteMessage, {
      disabled: Boolean(validationError),
      isCurrentUserAuthenticated: isCurrentUserAuthenticated,
      newMessage: newMessage,
      onSubmitMessage: onSubmitMessage,
      onUpdateNewMessage: handleOnChange
    })), !isEnabled && React.createElement("div", {
      className: "padding-1 f-100 align-center fc-grey-400"
    }, !isRetail ? 'Något gick fel, chatten kunde inte visas.' : 'Butiken har inaktiverat kommentarsfältet.'));
  });
};
setGlobal('svs.components.chat.chatUi.ChatComponent', ChatComponent);

 })(window);