(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/message-context-menu.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/message-context-menu.js');
"use strict";


const {
  useDispatch
} = ReactRedux;
const {
  showDialog
} = svs.components.marketplaceData.dialogWrapper.actions;
const {
  USER_STATUS_FORMER_MEMBER
} = svs.components.chat.chatData.constants;
const {
  ContextMenu,
  ContextMenuItem
} = svs.components.lbUi.contextMenu;
const MessageContextMenu = _ref => {
  let {
    userStatus,
    name,
    onBlockUser,
    onDeleteMessage
  } = _ref;
  const dispatch = useDispatch();
  const blockTitle = "Vill du verkligen hindra ".concat(name, " fr\xE5n att kommentera?");
  const blockButtons = [{
    text: 'Avbryt',
    classes: {
      inverted: true
    }
  }, {
    text: 'Blockera',
    classes: {
      focus: true
    },
    callback: () => {
      onBlockUser();
    }
  }];
  const removeMsgTitle = "Vill du verkligen ta bort kommentaren?";
  const removeMsgButtons = [{
    text: 'Avbryt',
    classes: {
      inverted: true
    }
  }, {
    text: 'Ta bort',
    classes: {
      focus: true
    },
    callback: () => {
      onDeleteMessage();
    }
  }];
  const handleDeleteMessage = () => {
    dispatch(showDialog({
      title: removeMsgTitle,
      text: '',
      buttons: removeMsgButtons,
      icon: 'dustbin'
    }));
  };
  const handleBlockUser = () => {
    dispatch(showDialog({
      title: blockTitle,
      text: '',
      buttons: blockButtons,
      icon: 'chat-host'
    }));
  };
  const canBlockUser = userStatus !== USER_STATUS_FORMER_MEMBER;
  return React.createElement(ContextMenu, null, React.createElement(ContextMenuItem, {
    className: "qa-action-btn",
    onClick: handleDeleteMessage
  }, "Ta bort meddelande"), canBlockUser && React.createElement(ContextMenuItem, {
    className: "qa-action-btn",
    onClick: handleBlockUser
  }, "Blockera anv\xE4ndare"));
};
setGlobal('svs.components.chat.chatUi.MessageContextMenu', MessageContextMenu);

 })(window);