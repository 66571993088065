(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-mobile-container.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-mobile-container.js');

'use strict';

const {
  ChatMobile
} = svs.components.chat.chatUi;
const {
  chatData
} = svs.components.chat;
const mapStateToProps = state => ({
  visible: state.Chat.isOpen
});
const mapDispatchToProps = dispatch => ({
  toggleChat: roomId => dispatch(chatData.actions.toggleChat(roomId))
});
svs.components.chat.chatUi.ChatMobileContainer = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ChatMobile);

 })(window);