(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/message.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/message.js');


'use strict';

const uiMockable = svs.ui;
const reactLoaderMockable = svs.components.ReactLoader;
const {
  formatDate
} = svs.components.lbUtils.helpers.formatDate;
const {
  default: TippyReact
} = Tippy;
const {
  MessageContextMenu
} = svs.components.chat.chatUi;
const {
  MESSAGE_STATUS_SEND_PENDING,
  MESSAGE_STATUS_DELETE_PENDING,
  MESSAGE_STATUS_SEND_FAILED,
  MESSAGE_STATUS_DELETE_FAILED,
  MESSAGE_STATUS_SENT,
  MESSAGE_STATUS_DELETED,
  MESSAGE_STATUS_NORMAL,
  MESSAGE_STATUS_EDITED,
  MESSAGE_STATUS_INFO,
  USER_STATUS_NORMAL,
  USER_STATUS_FORMER_MEMBER
} = svs.components.chat.chatData.constants;
const messageStatusClasses = {
  [MESSAGE_STATUS_INFO]: 'chat-comment--is-informal',
  [MESSAGE_STATUS_SEND_PENDING]: 'chat-comment--is-send-pending',
  [MESSAGE_STATUS_SEND_FAILED]: 'chat-comment--is-send-failed',
  [MESSAGE_STATUS_SENT]: 'chat-comment--is-sent',
  [MESSAGE_STATUS_DELETE_FAILED]: 'chat-comment--is-delete-failed',
  [MESSAGE_STATUS_DELETE_PENDING]: 'chat-comment--is-delete-pending',
  [MESSAGE_STATUS_DELETED]: 'chat-comment--is-deleted'
};
const messageStatusTexts = {
  [MESSAGE_STATUS_SEND_PENDING]: 'Skickar...',
  [MESSAGE_STATUS_SENT]: 'Skickat',
  [MESSAGE_STATUS_SEND_FAILED]: 'Det gick inte att skicka meddelandet. Försök igen.',
  [MESSAGE_STATUS_DELETE_FAILED]: 'Meddelandet gick inte att ta bort. Försök igen.'
};
const userStatusAliases = {
  [USER_STATUS_FORMER_MEMBER]: 'Före detta medlem'
};
const statusesOkToDelete = [MESSAGE_STATUS_NORMAL, MESSAGE_STATUS_DELETE_FAILED, MESSAGE_STATUS_SEND_FAILED, MESSAGE_STATUS_SENT, MESSAGE_STATUS_EDITED];
const Message = React.forwardRef((_ref, ref) => {
  let {
    canEditUser,
    message,
    createdAt,
    editedAt,
    name,
    isMe,
    isRetailer,
    messageStatus,
    userStatus,
    onDeleteMessage,
    onResendMessage,
    onBlockUser,
    wrap
  } = _ref;
  const containerClassnames = ['chat-comment'];
  const senderClassnames = ['qa-chat-sender-name', 'chat-comment--customer-name', 'overflow-threedots', 'f-bold'];
  const showDeleteButton = isMe && statusesOkToDelete.indexOf(messageStatus) > -1;
  const resolveMessageByStatus = (message, status) => {
    switch (status) {
      case MESSAGE_STATUS_DELETE_PENDING:
        return 'Meddelande tas bort...';
      case MESSAGE_STATUS_DELETED:
        return 'Meddelande borttaget';
      case MESSAGE_STATUS_INFO:
        return message.charAt(0).toUpperCase() + message.substr(1);
      default:
        return message;
    }
  };
  const isUserEditable = () => !isMe && messageStatus !== MESSAGE_STATUS_INFO && messageStatus !== MESSAGE_STATUS_DELETED && canEditUser && !isRetailer;
  if (isMe) {
    containerClassnames.push('chat-comment--is-self');
    senderClassnames.push('chat-comment-has-remove-btn');
  }
  const statusClass = messageStatusClasses[messageStatus];
  statusClass && containerClassnames.push(statusClass);
  const statusText = messageStatusTexts[messageStatus];
  const _getStatusIcon = React.useCallback(() => {
    if (messageStatus === MESSAGE_STATUS_SEND_FAILED) {
      return React.createElement("button", {
        className: "icon-button qa-resend-message",
        onClick: onResendMessage,
        type: "button"
      }, React.createElement(uiMockable.ReactIcon, {
        icon: "refresh",
        size: "200"
      }));
    }
    if (messageStatus === MESSAGE_STATUS_DELETE_FAILED) {
      return React.createElement(uiMockable.ReactIcon, {
        icon: "warning",
        size: "200"
      });
    }
    if (messageStatus === MESSAGE_STATUS_DELETE_PENDING || messageStatus === MESSAGE_STATUS_SEND_PENDING) {
      return React.createElement(reactLoaderMockable.Loader, {
        inverted: true,
        size: 200
      });
    }
    return null;
  }, [messageStatus, onResendMessage]);
  const statusIcon = _getStatusIcon();
  const messageText = resolveMessageByStatus(message, messageStatus);
  const formatedDate = [formatDate(createdAt, true, true, true)];
  messageStatus === MESSAGE_STATUS_EDITED && formatedDate.push(" (\xE4ndrat ".concat(formatDate(editedAt, true, true, false), ")"));
  return React.createElement("div", {
    className: containerClassnames.join(' '),
    ref: ref
  }, React.createElement("div", {
    className: "chat-comment--inner"
  }, React.createElement("div", {
    className: senderClassnames.join(' ')
  }, isRetailer && React.createElement("div", {
    className: "chat-comment-badge-container margin-right-half"
  }, React.createElement("div", {
    className: "chat-comment-badge"
  }, React.createElement(uiMockable.ReactIcon, {
    color: "fc-white",
    icon: "vinnare",
    size: "100"
  }))), userStatusAliases[userStatus] || name), React.createElement("div", {
    className: "chat-comment--text"
  }, React.createElement("span", {
    className: "chat-comment--text-nl qa-chat-message"
  }, messageText)), isUserEditable() && React.createElement("span", {
    className: "f-right chat-edit-container chat-comment--modify-container"
  }, React.createElement(TippyReact, {
    appendTo: () => document.body,
    content: React.createElement(MessageContextMenu, {
      name: name,
      onBlockUser: onBlockUser,
      onDeleteMessage: onDeleteMessage,
      userStatus: userStatus
    }),
    interactive: true,
    placement: "bottom-end",
    theme: "light-border",
    trigger: "click",
    zIndex: 1900
  }, React.createElement("button", {
    "aria-label": "Hantera ".concat(name),
    className: "qa-chat-edit-button icon-button chat-edit-btn",
    type: "button"
  }, React.createElement(uiMockable.ReactIcon, {
    color: "black",
    icon: "nav-more-vertical",
    size: "100"
  })))), showDeleteButton && React.createElement("button", {
    className: "chat-comment--remove-btn qa-remove-chat-msg icon-button",
    "data-testid": "remove-chat-msg",
    onClick: onDeleteMessage,
    title: "Ta bort",
    type: "button"
  }, React.createElement(uiMockable.ReactIcon, {
    color: "fc-white",
    icon: "remove-sign",
    size: "100"
  }), React.createElement("span", {
    className: "sr-only"
  }, "Ta bort kommentar")), React.createElement("div", {
    className: "chat-comment--info qa-chat-comment-info"
  }, statusText || formatedDate)), Boolean(wrap) && React.createElement("div", {
    className: "chat-comment-info-wrapper"
  }, statusIcon));
});
Message.defaultProps = {
  wrap: true
};
setGlobal('svs.components.chat.chatUi.Message', Message);

 })(window);