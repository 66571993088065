(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/message-list.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/message-list.js');

'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
const chatUiMockable = svs.components.chat.chatUi;
const {
  MoreMessagesButton
} = svs.components.chat.chatUi;
const {
  MESSAGE_STATUS_SEND_PENDING,
  MESSAGE_STATUS_SEND_FAILED,
  MESSAGE_STATUS_SENT,
  MESSAGE_STATUS_DELETE_PENDING,
  MESSAGE_STATUS_DELETE_FAILED,
  MESSAGE_STATUS_DELETED,
  MESSAGE_STATUS_NORMAL,
  MESSAGE_STATUS_EDITED,
  MESSAGE_STATUS_INFO
} = svs.components.chat.chatData.constants;
const SCROLL_OFFSET_NEW_MESSAGES = 10;
const ReactSwiper = React.forwardRef((props, ref) => React.createElement(svs.components.ReactSwiper, _extends({}, props, {
  forwardedRef: ref
})));

class MessageList extends React.Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "state", {
      showNewMessageHint: false
    });
    _defineProperty(this, "swiperRef", React.createRef());
    _defineProperty(this, "paginatorRef", React.createRef());
    _defineProperty(this, "lastMessageRef", React.createRef());
    _defineProperty(this, "intersectionObserver", null);
    _defineProperty(this, "timeoutId", null);
    _defineProperty(this, "handleIntersect", entries => {
      entries.forEach(entry => {
        if (entry.target === this.paginatorRef.current) {
          if (!this.props.loading && !this.props.hasLoadedEverything && entry.isIntersecting) {
            this.props.onFetchMessages({
              fetchRoom: this.props.isCurrentUserAuthenticated
            });
          }
        } else if (this.state.showNewMessageHint && entry.intersectionRatio > 0.4) {
          this.setState({
            showNewMessageHint: false
          });
        }
      });
    });
  }
  componentDidMount() {
    this.scrollToBottom();
    this.intersectionObserver = new IntersectionObserver(this.handleIntersect, {
      root: this.swiperRef.current,
      threshold: [0, 0.5]
    });
    this.timeoutId = setTimeout(() => this.intersectionObserver.observe(this.paginatorRef.current));
  }

  getSnapshotBeforeUpdate(prevProps) {
    const oldMsgList = prevProps.messageList;
    const newMsgList = this.props.messageList;
    if (oldMsgList === newMsgList) {
      return {};
    }
    if (oldMsgList.length === newMsgList.length) {
      return {};
    }
    const snapshot = {};
    const {
      scrollHeight,
      clientHeight,
      scrollTop
    } = this.swiperRef.current;
    if (oldMsgList.length && newMsgList.length && oldMsgList[0].id !== newMsgList[0].id) {
      snapshot.didReceiveOldMessagesUpTop = true;
      snapshot.prevScrollBottom = scrollHeight - scrollTop;
    } else {
      const oldLast = oldMsgList[oldMsgList.length - 1] || {};
      const newLast = newMsgList[newMsgList.length - 1];
      if (newLast && (oldLast.id !== newLast.id || oldLast.localId !== newLast.localId)) {
        snapshot.didReceiveNewMessagesDownBelow = true;
        const isCloseToBottom = scrollHeight - clientHeight - SCROLL_OFFSET_NEW_MESSAGES < scrollTop;
        snapshot.shouldScrollDown = isCloseToBottom || newLast.isMe;
        if (this.lastMessageRef.current) {
          this.intersectionObserver.unobserve(this.lastMessageRef.current);
        }
      }
    }
    return snapshot;
  }
  componentDidUpdate(prevProps, prevState) {
    let snapshot = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    if (snapshot.didReceiveNewMessagesDownBelow) {
      this.intersectionObserver.observe(this.lastMessageRef.current);
      if (snapshot.shouldScrollDown) {
        this.scrollToBottom();
      } else if (!this.state.showNewMessageHint) {
        this.setState({
          showNewMessageHint: true
        }); 
      }
    } else if (snapshot.didReceiveOldMessagesUpTop) {
      this.swiperRef.current.scrollTop = this.swiperRef.current.scrollHeight - snapshot.prevScrollBottom;
    }
  }
  componentWillUnmount() {
    this.intersectionObserver.disconnect();
    clearTimeout(this.timeoutId);
  }
  scrollToBottom() {
    this.swiperRef.current.scrollTop = this.swiperRef.current.scrollHeight;
  }
  render() {
    const {
      messageList,
      onDeleteMessage,
      onDeleteQueuedMessage,
      onResendMessage,
      error,
      loading,
      hasLoadedEverything,
      onFetchMessages,
      onRetryReconnect,
      canEditUser,
      onBlockUser
    } = this.props;
    const list = messageList.map((_ref, n) => {
      let {
        id,
        localId,
        message,
        isMe,
        isRetailer,
        name,
        createdAt,
        editedAt,
        status,
        userStatus,
        customerId
      } = _ref;
      const deleter = id ? () => onDeleteMessage(id) : () => onDeleteQueuedMessage(localId);
      const handleBlockUser = () => onBlockUser(customerId);
      const resender = () => onResendMessage(localId);
      return React.createElement(chatUiMockable.Message, {
        canEditUser: canEditUser,
        createdAt: createdAt,
        editedAt: editedAt,
        isMe: isMe,
        isRetailer: isRetailer,
        key: id || localId || "robot_".concat(message),
        message: message,
        messageStatus: status,
        name: name,
        onBlockUser: handleBlockUser,
        onDeleteMessage: deleter,
        onResendMessage: resender,
        ref: n === messageList.length - 1 ? this.lastMessageRef : undefined,
        userStatus: userStatus,
        wrap: this.props.isCurrentUserAuthenticated
      });
    });
    return React.createElement(React.Fragment, null, React.createElement(ReactSwiper, {
      className: "chat-message-list-swiper",
      "data-testid": "chat-message-list",
      ref: this.swiperRef
    }, React.createElement("div", {
      className: "chat-message-list ".concat(!list.length ? ' chat-message-list--empty' : '')
    }, React.createElement("div", {
      className: "chat-message-list-inner qa-scroll-box"
    }, React.createElement(chatUiMockable.ChatPageLoader, {
      empty: !list.length,
      error: error,
      hasLoadedEverything: hasLoadedEverything,
      loading: loading,
      onFetchMessages: onFetchMessages,
      onRetryReconnect: onRetryReconnect,
      ref: this.paginatorRef
    }), list))), React.createElement(MoreMessagesButton
    , {
      onClick: this.scrollToBottom,
      show: this.state.showNewMessageHint
    }));
  }
}
MessageList.defaultProps = {
  isCurrentUserAuthenticated: true,
  messageList: []
};
setGlobal('svs.components.chat.chatUi.MessageList', MessageList);

 })(window);